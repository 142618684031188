import React     from 'react';
import PropTypes from 'prop-types';

class CircleLoader extends React.Component {

	render() {
		return(
			<div className={"circle-loader " + this.props.className}>
				<svg className="circle-loader__circular" viewBox="25 25 50 50">
					<circle className="circle-loader__path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
				</svg>
			</div>
		);
	}
}

CircleLoader.propTypes = {
	className: PropTypes.string,
};

CircleLoader.defaultProps = {
	className: '',
};

export default CircleLoader;
